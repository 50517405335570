<template>
  <div class>
    <router-view></router-view>
    <div class="p32 p-t-32" v-if="$route.path == '/user/ProcurementPlanning'">
      <div class="tt df">
        <div class="suxian"></div>
        我的采购计划
      </div>

      <div class="serchtab">
        <div class="li">
          <div class="serchName">商品名称</div>
          <div class="ipt">
            <el-input
              clearable
              placeholder="请输入商品名称"
              v-model="goodsName"
            >
            </el-input>
          </div>
        </div>
        <div style="justify-content: center" class="li">
          <div class="serchName">客户</div>
          <div style="flex: 0" class="ipt">
            <el-input clearable placeholder="输入客户" v-model="customerName">
            </el-input>
          </div>
        </div>
        <div class="li">
          <div style="flex: 1" class="serchName">联系人</div>
          <div class="ipt">
            <el-input
              clearable
              placeholder="输入联系人名称"
              v-model="contactsName"
            >
            </el-input>
          </div>
        </div>
        <div class="li">
          <div class="serchName">采购单号</div>
          <div class="ipt">
            <el-input clearable placeholder="输入采购单号" v-model="orderSn">
            </el-input>
          </div>
        </div>
        <div style="justify-content: center" class="li">
          <div class="serchName">生成时间</div>
          <div style="flex: 0" class="ipt">
            <el-date-picker
              v-model="startDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择时间"
            ></el-date-picker>
          </div>
        </div>
        <div class="li">
          <div class="ss">
            <el-button @click="serchList" type="success">搜索</el-button>
            <el-button @click="resetFn">重置</el-button>
          </div>
        </div>
      </div>
      <div class="totalList">
        <div style="margin-bottom: 24px" class="df aic">
          <div
            @click="changeTabFn(1)"
            :class="changeTab == 1 ? 'hd active' : 'hd'"
          >
            全部计划
          </div>
          <div
            @click="changeTabFn(2)"
            :class="changeTab == 2 ? 'hd active' : 'hd'"
          >
            <el-badge
              :value="orderCount.waitPayCount"
              :max="99"
              color="#ff2832"
              class="item"
              >待审核</el-badge
            >
          </div>
          <div
            @click="changeTabFn(3)"
            :class="changeTab == 3 ? 'hd active' : 'hd'"
          >
            <div>审核中</div>
          </div>
          <div
            @click="changeTabFn(4)"
            :class="changeTab == 4 ? 'hd active' : 'hd'"
          >
            <div>已撤销</div>
          </div>
          <div
            @click="changeTabFn(5)"
            :class="changeTab == 5 ? 'hd active' : 'hd'"
          >
            <div>已驳回</div>
          </div>
          <div
            @click="changeTabFn(6)"
            :class="changeTab == 6 ? 'hd active' : 'hd'"
          >
            <div>已完成</div>
          </div>
        </div>

        <div
          style="min-height: 600px"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <div v-if="this.productList.length == 0" class="none">
            <img src="@/assets/images/kongList.png" alt />
            <div class="empty-tips">
              您还没有订单哦!
              <span @click="$router.push('/home')" class="cup gr">去下单</span>
            </div>
          </div>

          <div v-if="this.productList.length > 0" class="have">
            <div class="li" v-for="(item, index) in productList" :key="index">
              <div class="head df">
                <div style="width: 100%" class="content">
                  <div class="bg"></div>
                  <li>
                    <div class="cld realpay">
                      实付总金额：
                      <span style="color:#E33233">¥ 待定价</span>
                    </div>
                    <div class="su"></div>
                    <div class="cld">
                      采购单号：
                      <span>{{ item.sn }}</span>
                    </div>
                    <div class="su"></div>

                    <div class="cld">
                      下单时间：
                      <span>{{ item.createTime }}</span>
                    </div>
                    <div
                      class="cld"
                      style="color: #26c487; flex: 1; justify-content: flex-end"
                    >
                      <!-- 待付款状态 //订单状态：-1全部 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150   已完成-160 已关闭-170-->
                      <div
                        v-if="item.status === 100 || item.status === 500"
                        class="daifukuan"
                      >
                        <span>待审核</span>
                      </div>
                      <div v-if="item.status === 200" class="daifukuan">
                        <span>待审中</span>
                      </div>
                      <div v-if="item.status === 300" class="daishouhuo">
                        已完成
                      </div>
                      <div v-if="item.status === 400" class="yibohui">
                        已驳回
                      </div>
                      <div v-if="item.status == 600" class="yiguanbi">
                        已撤销
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="cld">
                      客户：
                      <span>{{ item.customerName }}</span>
                    </div>
                    <div class="su"></div>
                    <div class="cld">
                      收货方式：
                      <span>
                        {{ item.receiveType == 1 ? "送货上门" : "到仓自提" }}
                      </span>
                    </div>
                  </li>
                  <li class="df flex-between">
                    <div class="cld">
                      <div>
                        {{ item.receiveType == 1 ? "收货人" : "提货人" }}信息：
                      </div>

                      <span>
                        {{ item.takeName || item.receiveName }}
                        {{ item.takePhone || item.receivePhone }}
                        {{ item.receiveAddress }}
                      </span>
                    </div>
                    <div
                      v-show="item.status !== 300"
                      @click="EditApprovalStatus(item)"
                      class="callback "
                    >
                      撤销
                    </div>
                  </li>
                </div>
              </div>
              <div>
                <div class="cardlist">
                  <div v-for="(it, idx) in item.productDetailDTOS" :key="idx">
                    <div v-if="idx < 2">
                      <div class="bigl">
                        <Product-Card
                          :waitPrice="true"
                          :isMyorder="true"
                          :rows="it"
                        >
                        </Product-Card>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.productDetailDTOS.length - 2 > 0"
                    class="su"
                  ></div>
                  <div
                    v-if="item.productDetailDTOS.length - 2 > 0"
                    class="listNum"
                  >
                    <div class="num nwp">
                      剩余{{
                        item.productDetailDTOS.length - 2 > 0
                          ? item.productDetailDTOS.length - 2
                          : 0
                      }}类商品
                    </div>
                  </div>

                  <div
                    v-if="item.productDetailDTOS.length - 2 > 0"
                    class="su"
                  ></div>
                  <div class="listNum">
                    <div
                      v-if="item.productDetailDTOS.length <= 2"
                      style="margin-right:20px"
                      class="su"
                    ></div>
                    <div style="justify-content: center" class="caozuo df">
                      <div @click="goDetail(item)" class="xiangqing gr cup">
                        查看详情
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="total > 20" class="pagenation">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="20"
            layout="prev, pager, next, total,jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/custom-base/custom-product-card";
import {
  mallPurchasePlanDetail,
  findMallApprovalNum,
  mallEditApprovalStatus,
} from "@/request/Orders/order";
import { multiply } from "@/utils/math";
export default {
  components: {
    ProductCard,
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(n, o) {
  //       if (n) {
  //         this.getList();
  //       }
  //     },
  //   },
  // },
  created() {
    this.getList();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  mounted() {
    // this.timer = setInterval(() => {
    //   let _this = this;
    //   _this.daojishi();
    // }, 1000);
  },
  activated() {},
  data() {
    return {
      moreStatus: false,
      otherStatus: "更多状态",
      loading: false,
      total: 1,
      page: 1,
      timer: null,
      productList: [],
      //切换状态
      changeTab: 1,
      status: null, //
      orderCount: {
        waitPayCount: "0",
      },
      contactsName: "",
      customerName: "",
      endDate: "",
      goodsName: "",
      orderSn: "",
      startDate: "",
    };
  },
  methods: {
    genum() {
      findMallApprovalNum().then((res) => {
        if (res.code == 200) {
          this.orderCount.waitPayCount = res.data.waitApproval;
        }
      });
    },
    EditApprovalStatus(item) {
      let data = {
        approvalStatus: 600,
        sn: item.sn,
      };
      this.$confirm("是否确认撤销审批?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        mallEditApprovalStatus(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("撤销成功");
          }
          this.getList();
        });
      });
    },
    goDetail(item) {
      this.changeTabFn(1);
      this.$router.push({
        name: "ProcurementDetail",
        params: { id: item.sn },
      });
    },
    multiply,
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    showtime(endtime) {
      var nowtime = new Date(), //获取当前时间
        endtime = new Date(endtime); //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftd > -1 && lefth > -1) {
        if (leftd >= 1) {
          return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        } else if (leftd <= 0 && lefth >= 1) {
          return lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        } else {
          return leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        }
      }
    },
    serchList() {
      this.page = 1;
      this.getList();
    },
    daojishi() {
      this.productList.map((item) => {
        let endtime = "";
        endtime = this.showtime(item.payEndTime);
        item.endtime = endtime;
      });
      this.$forceUpdate();
    },
    changeTabFn(index, item) {
      switch (index) {
        case 1:
          //全部
          this.changeTab = index;
          this.status = null;
          // this.otherStatus = "全部";
          break;
        case 2:
          // this.otherStatus = "待审核";
          //待审核
          this.changeTab = index;
          this.status = 100;
          break;

        case 3:
          // this.otherStatus = "审核中";
          //审核中
          this.changeTab = index;
          this.status = 200;
          break;
        case 4:
          // this.otherStatus = "已撤销";
          //已撤销
          this.changeTab = index;
          this.status = 600;
          break;
        case 5:
          // this.otherStatus = "已驳回";
          //已驳回
          this.changeTab = index;
          this.status = 400;
          break;
        case 6:
          //已完成
          this.changeTab = index;
          this.status = 300;
          break;

        default:
          break;
      }
      this.getList();
    },
    //重置按钮
    resetFn() {
      this.contactsName = ""; //联系人名称
      this.customerName = ""; //客户名称
      this.endDate = "";
      this.goodsName = "";
      this.orderSn = "";
      this.startDate = "";
    },
    //获取订单列表
    getList() {
      this.loading = true;
      let Postdata = {
        applyTime: this.startDate, //开始日期
        approvalStatus: this.status, //审批状态：100待审批 300上级审批通过（整体审批通过） 500 上级审批驳回 600 撤销
        customerAddrId: this.$store.state.AddrId, //客户地址id
        customerName: this.customerName, //客户名称
        isAsc: "asc",
        pageNum: this.page,
        pageSize: 20,
        productName: this.goodsName, //商品名称
        receiveName: this.contactsName, //联系人人姓名
        sn: this.orderSn, //采购单号
        userId: this.$store.state.owerId,
      };

      if (!this.$store.state.AddrId) {
        this.productList = [];
        this.loading = false;
        return;
      }
      this.genum();
      mallPurchasePlanDetail(Postdata)
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.productList = res.data.rows;
            this.total = res.data.total;

            this.productList.map((item) => {
              item.productDetailDTOS.map((it) => {
                it.transImg = it.productPic ? it.productPic.split(",") : "";
                if (it.transImg.length !== 0) {
                  let urlImg = [];
                  it.transImg.map((i) => {
                    if (it.productPic && it.lastIndexOf("show") !== -1) {
                      urlImg.push(i);
                    }
                  });
                  it.productPic =
                    urlImg.length > 0 ? urlImg[0] : it.productPic.split(",");
                }
              });
            });
          } else {
            this.productList = [];
            this.loading = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-loading-spinner i {
    color: #26c487;
  }
  .el-loading-spinner .el-loading-text {
    color: #26c487;
  }
  .el-checkbox__inner {
    border: 1px solid #26c487;
  }
  .el-tag {
    text-align: center;
    width: 50px;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
  }
  .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 6px;
    transform: translateY(-50%) translateX(100%);
  }
  .el-tabs__item.is-active {
    color: @green;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 275px;
  }
  .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: @green;
    z-index: 1;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
}
.black {
  color: #333;
}
.realpay {
  font-size: 14px !important;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #333 !important;
}
.callback {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  cursor: pointer;
  font-size: 14px;
  font-family: 'regular';
  font-weight: 400;
  color: #26c487;
}
.moreStatus {
  position: relative;
  .moreChild {
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    padding: 0 18px;
    left: 0;
    /* border-left: 1px solid #d7dae2;
          border-right: 1px solid #d7dae2;
          border-bottom: 1px solid #d7dae2; */
    text-align: center;
    background: #fff;
    position: absolute;
    top: 30px;
  }
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.pagenation {
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.daifukuan {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: rgb(249, 235, 217);
  border-radius: 2px 2px 2px 2px;
  font-weight: 400;
  color: #faa743;
}
.daifukuanhd {
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 0 20px;
  align-items: center;
  height: 42px;
  background: #f4fffb;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid rgba(38, 196, 135, 0.34);
  filter: blur(undefinedpx);
  font-size: 14px;
}
.daishouhuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #d4efe7;
  border-radius: 2px 2px 2px 2px;
  color: #3ec993;
}

.daifahuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #f8eadb;
  border-radius: 2px 2px 2px 2px;
  color: #f9a948;
}
.yibohui {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #ffe2e6;
  border-radius: 2px 2px 2px 2px;
  color: #e33233;
}
.yiguanbi {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #e9e9ed;
  border-radius: 2px 2px 2px 2px;
  color: #a2a3ae;
}
.serchtab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .li {
    height: 36px;
    margin-bottom: 20px;
    line-height: 36px;
    width: 32%;
    display: flex;
    justify-content: space-between;
    .serchName {
      min-width: 60px;
      margin-right: 10px;
      font-size: 14px;
      text-align: right;
      color: #333333;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
      .el-input {
        width: 220px;
      }
    }
  }
}
.totalList {
  .hd {
    font-size: 16px;
    cursor: pointer;
    margin-right: 26px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 4px solid transparent;
  }
  .active {
    color: @green;
    border-bottom: 4px solid @green;
  }
  .li {
    margin-bottom: 20px;
    width: 100%;
    background: #fff;
    border: 1px solid @hui;
    box-sizing: border-box;
    .head {
      width: 100%;
      /* height: 120px; */
      box-sizing: border-box;
      background: #f7f8fa;
      border-bottom: 1px solid @hui;
      padding: 18px 20px 11px 17px;
      .content {
        li {
          margin-bottom: 10px;
          line-height: 20px;
          font-size: 14px;
          display: flex;
          align-items: center;
          color: #595a6d;
          .cld {
            font-size: 12px;
            display: flex;
            color: #595a6d;
          }
          .su {
            margin: 0 14px;
            border: 1px solid #e7ebe7;
            display: inline-block;
            vertical-align: middle;
            border-left: 1px solid @hui;
            content: "";
            height: 12px;
            line-height: 12px;
          }
        }
      }
    }

    .cardlist {
      align-items: center;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px 22px;
      .su {
        margin-top: 2px;
        display: inline-block;
        vertical-align: middle;
        border-right: 1px solid @hui;
        content: "";
        height: 79px;
        padding-left: 10px;
        line-height: 79px;
      }
      .listNum {
        display: flex;
        text-align: center;
        align-items: center;
        padding: 0 6px;
        .num {
          font-size: 14px;
          color: #bbbccb;
        }
      }
      .bigl:nth-child(2n) {
        margin-left: 30px;
      }

      .price {
        border-top: 1px dashed #f5f5f5;
        padding-top: 5px;
        display: flex;
        justify-content: flex-end;
        height: 20px;
        line-height: 20px;
        .ge {
          margin-right: 60px;
          font-size: 14px;
          color: #adafad;
        }
        .total {
          margin-right: 20px;
          font-size: 14px;
          font-weight: bold;
          color: @green;
        }
      }
      .lii {
        padding-bottom: 14px;
        border-bottom: 2px dashed @hui;
        display: flex;
        min-width: 350px;
        /* width: 360px; */

        .card {
          text-align: center;
          border: 1px solid @hui;
          width: 86px;
          height: 92px;
          box-sizing: border-box;
        }
        .goodsDetail {
          flex: 1;
          margin-left: 16px;
          line-height: 1.4;
          .h1 {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
          }
          .info {
            font-size: 12px;
            color: #999999;
          }
        }
      }
      .caozuo {
        width: 80px;
        text-align: center;
        white-space: nowrap;
        .zhifu {
          font-size: 14px;
          color: #26c487;
          margin-bottom: 20px;
        }
        .xiangqing {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
