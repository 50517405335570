/*
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-11 12:44:36
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-11 13:23:00
 */
import * as math from 'mathjs'
export function add(num1, num2) {
  return math.add(math.bignumber(num1), math.bignumber(num2))
}
export function subtract(num1, num2) {
  return math.subtract(math.bignumber(num1), math.bignumber(num2))
}
export function multiply(num1, num2) {
  return math.multiply(math.bignumber(num1), math.bignumber(num2))
}
export function divide(num1, num2) {
  return math.divide(math.bignumber(num1), math.bignumber(num2))
}
